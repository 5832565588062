import {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Card, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {formProps} from "../Types/formDataType";
import {useAppDispatch, useAppSelector} from "../store/store";
import {addPatient, removePatient} from "../store/features/patientSlice";
import {billingApiUrl} from "../Components/APIs/urls";
import {addProductionSteps, removeSteps} from "../store/features/stepSliceProduction";
//import { UserLinkIdStatus } from "../App";
import {continueTrue} from "../store/features/continueSteps";
import {UserContext} from "../Types/UserContext";
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from '../FirebaseConfig'
import {resetAuthObj, updateDate, updateToken} from "../store/features/authSlice";
import {updateLink, updateLinkId, updateLinkIdType} from "../store/features/linkSlice";
import localStorage from "redux-persist/es/storage";
import Cookies from "universal-cookie";
import {getAuth} from "firebase/auth";
import {useTranslation} from "react-i18next";


export default function PatientInformation({persistor}: any, {link}: any) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const API_KEY = process.env.REACT_APP_API_KEY + "";


    const cookies = new Cookies();

    const {t, i18n} = useTranslation();
    const lng = navigator.language;


    //REDUX GETTERS
    const patientObj: any = useAppSelector((state) => state.patient.patientObj);
    const stepsProductionObj: any = useAppSelector((state) => state.stepProduction.stepsProductionObj[0]);
    const authObj: any = useAppSelector((state) => state.auth.authObj);
    const linkObj: any = useAppSelector((state) => state.link.linkObj);

    // USECONTEXT
    //const { setUserLinkId, userLinkId }: any = useContext<any>(UserLinkIdStatus);
    const {setUserLinkId, userLinkId} = useContext(UserContext)

    // USESTATES
    const [formData, setFormData] = useState<formProps>({
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        phone: "",
        email: "",
        paymentSource: "1",
        paymentType: "1",
    });


    const payTypeRef = useRef('1');
    const payTypeSource = useRef('1');
    const isClickedPatientInfo = useRef(false);

    const [message, setMessage] = useState<string>("");
    const [loadingPage, setLoadingPage] = useState(false);
    const [defaultType, setDefaultType] = useState("")
    const [errorPage, setErrorPage] = useState("");
    const [paymentMessage, setPaymentMessage] = useState("");
    const [isTokenExpired, setIsTokenExpired] = useState(false)

    const [user, loading, error] = useAuthState(auth);
    console.log("user", user);
    console.log("loading", loading);
    console.log("error", error);

    const symbolsNotAllowed = ["e", "E", "+", "-", "."];

    const isFirstRender = useRef(true);

    const clearStorage = (persist: any) => {
        isFirstRender.current = false;
        dispatch(removeSteps());
        dispatch(removePatient())

        persist.flush().then(() => {
            return persist.purge();
        })

        console.log("persist after flush", localStorage.getItem("persist:root"))
    }


    // GET LINK ID QUERY PARAM
    const linkKey = "linkId";
    const queryParams = new URLSearchParams(window.location.search);
    const linkIdParam: any = queryParams.get("id");
    const linkIdParamTypeId: any = queryParams.get("t");
    const linkIdRef = useRef(linkObj.link_id);
    const linkIdTypeRef = useRef(linkObj.link_id_type);
    const TokenValid = useRef(true);

    //*****PATIENT IF-CONTINUE CALL TO GET PREVIOUS STEP IN PROGRESS (WHERE LEFT OFF)******
    const stepsContinueCall = async () => {
        console.log("check if link id is stored in cookies", cookies.get('link_id'));
        try {
            console.log("calling steps continue", cookies.get('jwt_authorization'));
            // const res = await fetch(`${baseUrlContinueNode}`, { 
            const res = await fetch(`${billingApiUrl}/api/linksteps/script/${linkObj.link_id || cookies.get('link_id')}/check`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "ngrok-skip-browser-warning": "69420",
                    "x-api-key": API_KEY,
                    "Authorization": "Bearer" + " " + cookies.get('jwt_authorization')
                },
            });
            if (res.ok) {
                console.log("steps continue ok");
                const stepData: any = await res.json();
                dispatch(addProductionSteps(stepData.linkStepScript));
                console.log("after productionSteps add")
                dispatch(continueTrue())
                console.log("After continue True add", stepData)
                dispatch(addPatient({...stepData.person, paymentSource: "1", paymentType: "1"}));
                console.log("1st call: ", stepData);
                navigate(stepData.currentRoutePath);
            } else if (res.status === 404) {
                try {
                    getAuthToken();
                    TokenValid.current && patientInfoLoad();
                } catch (error) {
                    console.log(error)
                }
            }
            //Link Expired. , Process has completed. , Invalid API Key and API Key Missing.

            else if (res.status === 401) {
                try {
                    const result = await res.text()
                    if (result === 'Link Expired.') {
                        navigate("/linkexpired")
                    } else if (result === 'Link has been Cancelled.') {
                        navigate("/linkcancelled")
                    } else if (result === 'Process has completed.') {
                        navigate("/stepscomplete")
                    } else {
                        handleLogout();
                    }
                } catch (error) {
                    console.log("This is error", error)
                }
            } else {
                setLoadingPage(false);
                //document.body.style.backgroundColor = "#551A8B";
                setErrorPage(res.status + " " + res.statusText)
            }
        } catch (error) {
            // ****IF FIRST API CALL FAILS (THERE WAS NOT A PREVIOUS SESSION) THEN GOES INTO CATCH BLOCK AND CALLS PATIENT INFO LOAD****

        }
    };

    //****PATIENT INFO LOAD FETCH PATIENT INFO DATA*****
    const patientInfoLoad = async () => {
        console.log("token is check is token is saved in useState", cookies.get('jwt_authorization'));
        try {
            // const resp = await fetch(`${baseUrlPatientInfoNode}`, {
            const resp = await fetch(`${billingApiUrl}/api/patients/patientinfo/${cookies.get('link_id')}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "ngrok-skip-browser-warning": "69420",
                    "x-api-key": API_KEY,
                    "Authorization": "Bearer" + " " + cookies.get('jwt_authorization')
                },
            })

            if (resp.ok) {
                const data = await resp.json();
                setFormData(data);
                console.log("data from response", data);
                console.log("This is the formData in patient load", formData)

            } else if (!resp.ok) {
                setLoadingPage(false);
                //document.body.style.backgroundColor = "#551A8B";
                if (resp.statusText) {
                    setErrorPage(resp.status + " " + resp.statusText)
                } else {
                    const result = await resp.text()
                    setErrorPage(resp.status + " " + result)
                }
            }

        } catch (error: any) {
            navigate("*");
            console.log(error);
            console.log("This is the error mes", error.message);
        }
    }


    const payMsg = useRef("")

    const handleLogout = async () => {
        auth.signOut().then(() => {
            setIsTokenExpired(true);
            console.log("Token expired. Get new one")
            cookies.remove("jwt_authorization");
            dispatch(resetAuthObj());
            navigate("/auth")
        }).catch((err) => {
            console.log(err);
        })
    }


    const getAuthToken = async () => {

        //GET TOKEN
        const accessToken: any = await user?.getIdToken();

        //CHECK USER TOKEN RESULT AND GET TOKEN CLAIMS
        const getTokenResult = await getAuth().currentUser?.getIdTokenResult(false)
        const claimResult = getTokenResult?.claims

        //GET CURRENT TIME
        const currentTime: any = new Date();
        console.log("Current Time", currentTime);

        //GET AUTH TIME + 1 hr
        const authTime: any = claimResult?.auth_time
        const authTimeResultDateFormat = new Date(authTime * 1000);
        const authTimeResultDatePlusOneHour = new Date(authTimeResultDateFormat.setHours(authTimeResultDateFormat.getHours() + 1));
        console.log("Expired Time", authTimeResultDatePlusOneHour);
        dispatch(updateDate({exp_Date: authTimeResultDatePlusOneHour}))

        //COMPARE IF TOKEN IS STILL VALID
        console.log("Auth OBJ", authObj)
        const dateFormatted = new Date(Date.parse(authObj.exp_Date))
        console.log("Auth OBJ date formatted", dateFormatted)
        if (authTimeResultDatePlusOneHour || dateFormatted > currentTime) {
            console.log("authTimePlusOneHour", authTimeResultDatePlusOneHour);
            console.log("current time", currentTime);
            console.log("Token not yet expired")
        } else {
            TokenValid.current = false;
            console.log("TO LOGOUT BECAUSE TOKEN EXPIRED", TokenValid.current);
            handleLogout();
        }

        console.log("access Token getting token", accessToken)
        cookies.set("jwt_authorization", accessToken);
        dispatch(updateToken({access_token: accessToken}))
        console.log("JWT FROM COOKIE", cookies.get('jwt_authorization'))
    }


    console.log("FORM DATA Pay TYPE outside useEffect", formData.paymentType);

    //1. *** CALL 1 TO BACKEND API - GET PATIENT INFO FROM BACK END - FILL FORM WITH PATIENT INFO AND STORE IN FORMDATA ***
    useEffect(() => {
        console.log("FORM DATA Pay TYPE in useEffect", formData.paymentType);
        window.history.pushState(null, '', window.location.href);
        window.addEventListener("popstate", e => {
            window.history.pushState(null, '', window.location.href);
            console.log("EVENT IN BACK Patient Info", e)
            //navigate(1);
            return () => {
                window.removeEventListener('popstate', (e: PopStateEvent) => {

                })
            }

        })

        const queryParams = new URLSearchParams(window.location.search);
        const linkIdParam: any = queryParams.get("id");
        localStorage.setItem('link_id', linkIdParam);
        const redirectLinkPatientInfo: any = window.location.href
        console.log("Once back in patient info extracted id", linkIdParam)
        // setting the patient link id in REDUX and in USE CONTEXT
        setUserLinkId({id: linkIdParam})
        dispatch(updateLink({redirect_link: redirectLinkPatientInfo}))
        console.log("link id from link slice from cookies", linkObj.linkId);
        if (!user) {
            dispatch(updateLinkId({link_id: linkIdParam}))
            dispatch(updateLinkIdType({link_id_type: linkIdParamTypeId}))
            navigate("/auth");
            console.log("inside patient info", redirectLinkPatientInfo);
            console.log("inside patient info linkIdParam", linkIdParam)
            console.log("no user added linkid to redux", linkObj.link_id)
        } else {
            if (!cookies.get('link_id')) {
                console.log("L I N K   FROM Continue URL", link);
                if (linkIdRef.current) {
                    cookies.set("link_id", linkIdRef.current)
                    cookies.set("link_id_type", linkIdTypeRef.current)
                    dispatch(updateLinkId({link_id: linkIdRef.current}))
                    dispatch(updateLinkIdType({link_id_type: linkIdTypeRef.current}))
                }
                console.log("THIS IS USER ID ID", linkIdRef.current)
                console.log("This is the User id type", linkIdTypeRef.current);
            }
            console.log("USER AUTHENTICATED LINK ID", linkObj.link_id);
            console.log("ID Param", linkIdParam)
            console.log("ID Redux", linkObj.linkId)
            console.log("ID Redux", linkObj.redirect_link)
            console.log("ID userRef", linkIdRef.current)
            console.log("Link TYPE Ref", linkIdTypeRef.current);
            console.log("user token token right after getToken Method", cookies.get('jwt_authorization'))
            console.log("link id id", linkObj.link_id);
            setUserLinkId({id: linkObj.link_id})
            console.log("user link id in patient info", userLinkId.id);
            dispatch(updateLinkId({link_id: linkObj.link_id}))
            dispatch(updateLinkIdType({link_id_type: linkObj.link_id_type}))
            getAuthToken();
            console.log("AFTER getAuthToken before stepsContinue", cookies.get('jwt_authorization'));


            if (isFirstRender.current) {
                console.log("clear storage")
                clearStorage(persistor);
                console.log("USER LINK ID", linkObj.link_id)
                console.log("user token token to check if stored in userLinkID", userLinkId.id)
                console.log("user link type id in first render", linkObj.link_id_type);
            }
            if (!formData.firstName) {
                if (!cookies.get('jwt_authorization') || !cookies.get("link_id")) {
                    navigate('/auth');
                } else {
                    if (!cookies.get("link_id")) {
                        navigate("/auth");
                    } else {
                        if (isTokenExpired === false) {
                            console.log("INSIDE of isTokenExpiredCondition - calling steps continue")
                            getAuthToken();
                            TokenValid.current && stepsContinueCall();
                            console.log("form data: ", formData);
                        } else {
                            handleLogout();
                        }
                    }
                }
            }
        }


    }, []);


    console.log("Payment Source prior to being submitted", formData.paymentSource)
    console.log("Payment Type prior to being submitted", formData.paymentType)


    //CALLING TO GET STEPS
    const linkStepsPost = async () => {
        formData.paymentType = "1";
        formData.paymentSource = "1";

        const formParams = new URLSearchParams();
        formParams.append(linkKey, cookies.get('link_id'));
        formParams.append('linkTypeId', cookies.get('link_id_type'))
        // formParams.append("paymentTypeId", formData.paymentType);
        // formParams.append("paymentSourceId", formData.paymentSource);

        try {
            // const response = await fetch(`${baseUrlStepsNode}`, {
            const response = await fetch(`${billingApiUrl}/api/linksteps/script?` + formParams.toString(), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "ngrok-skip-browser-warning": "69420",
                    "x-api-key": API_KEY,
                    "Authorization": "Bearer" + " " + cookies.get('jwt_authorization')
                },
            });

            if (response.ok) {
                const data: any = await response.json();
                //if no data returned steps haven't been created
                if (!data) {
                    console.log("not data")
                    navigate("*")
                } else {
                    console.log("After get steps", data);
                    console.log("This is the form data: ", formData)
                    dispatch(addProductionSteps(data));
                    dispatch(addPatient(formData));
                    setLoadingPage(false);
                    console.log("once received", data[1].routePath);
                    navigate(data[1].routePath);
                }
            } else if (!response.ok) {
                setLoadingPage(false);
                //document.body.style.backgroundColor = "#551A8B";
                if (response.statusText) {
                    setErrorPage(response.status + " " + response.statusText)
                } else {
                    const result = await response.text()
                    setErrorPage(response.status + " " + result)
                }
            }
        } catch (error) {
            console.log(error);
            setLoadingPage(false);
            navigate("*")
        }
    }


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        isClickedPatientInfo.current = true;
        console.log("IS Clicked in Patient Info", isClickedPatientInfo.current)
        console.log("ON SUBMIT", userLinkId.id);
        console.log("ON SUBMIT 2", linkObj.link_id);
        console.log("ON SUBMIT 3", linkIdParam);
        console.log("ON SUBMIT 4", linkIdRef.current)
        console.log("ON SUBMIT 5", )
        setLoadingPage(true);
        getAuthToken();
        TokenValid.current && linkStepsPost();

    };


    return (
        <>
            {loadingPage && (
                <div className="spinner-cont">
                    <Spinner className="spinner" animation="border" role="status"
                             style={{width: "4rem", height: "4rem"}}>
                        <span className="visually-hidden">Loading</span>
                    </Spinner>
                </div>
            )}
            {!errorPage ?
                <>
                    <h1>{t('patientInfoTitle')}</h1>
                    {!user && !formData.firstName ?
                        <Container className="d-flex justify-content-center">
                            <h1>Loading...</h1>
                        </Container>
                        : <Container className="form-container">
                            <Form onSubmit={handleSubmit}>
                                <Container className="container-form-top">
                                    <Col className="col-top">
                                        <Form.Group className="top-field" controlId="formBasicFirstName">
                                            <Form.Label className="form-label">{t('patientInfoFormFname')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={formData.firstName}
                                                readOnly
                                                placeholder="Enter First Name"
                                                onChange={(e) => setFormData({...formData, firstName: e.target.value})}
                                            />
                                        </Form.Group>

                                        <Form.Group className="top-field" controlId="formBasicLastName">
                                            <Form.Label
                                                className="form-label">{t('patientInfoFormLastname')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={formData.lastName}
                                                readOnly
                                                placeholder="Enter Last Name"
                                                onChange={(e) => setFormData({...formData, lastName: e.target.value})}
                                            />
                                        </Form.Group>

                                        <Form.Group className="top-field" controlId="formBasicAddress">
                                            <Form.Label
                                                className="form-label">{t('patientInfoFormAddress')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={formData.address}
                                                readOnly
                                                placeholder="Enter Address"
                                                onChange={(e) => setFormData({...formData, address: e.target.value})}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Container>

                                <Container className="container-form-mid">
                                    <Row>
                                        <Col>
                                            <Form.Group className="mid-field" controlId="formBasicCity">
                                                <Form.Label
                                                    className="form-label">{t('patientInfoFormCity')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter City"
                                                    value={formData.city}
                                                    readOnly
                                                    onChange={(e) => setFormData({...formData, city: e.target.value})}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mid-field" controlId="formBasicState">
                                                <Form.Label
                                                    className="form-label">{t('patientInfoFormState')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={formData.state}
                                                    readOnly
                                                    placeholder="Enter State"
                                                    onChange={(e) => setFormData({...formData, state: e.target.value})}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mid-field" controlId="formBasicZip">
                                                <Form.Label
                                                    className="form-label">{t('patientInfoFormZip')}</Form.Label>
                                                <Form.Control
                                                    type=""
                                                    value={formData.zipCode}
                                                    readOnly
                                                    placeholder="Enter Zip"
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        zipCode: e.target.value
                                                    })}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container className="container-form-bottom">
                                    <Col>
                                        <Form.Group className="bottom-field" controlId="formBasicPhone">
                                            <Form.Label className="form-label">{t('patientInfoFormPhone')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={formData.phone}
                                                readOnly
                                                placeholder="Enter Phone"
                                                onChange={(e) => setFormData({...formData, phone: e.target.value})}
                                            />
                                        </Form.Group>

                                        <Form.Group className="bottom-field" controlId="formBasicEmail">
                                            <Form.Label className="form-label">{t('patientInfoFormEmail')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={formData.email}
                                                readOnly
                                                placeholder="Enter Email"
                                                onChange={(e) => setFormData({...formData, email: e.target.value})}
                                            />
                                        </Form.Group>

                                    </Col>
                                </Container>


                                <div className="info-btn-location">
                                    <Button
                                        className="btn-patient-info"
                                        disabled={
                                            !formData.firstName ||
                                            !formData.firstName ||
                                            isClickedPatientInfo.current}
                                        type="submit">{t('patientInfoAgreeBtn')}
                                    </Button>
                                </div>
                            </Form>
                        </Container>}
                </>
                :
                <Container className="error-display-container">
                    <Card className="error-card">
                        <div className="error-box">
                            <h3>Error {errorPage}</h3>
                        </div>
                    </Card>
                </Container>}
        </>
    );
}
