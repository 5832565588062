import {Container} from "react-bootstrap";
import {useAppSelector} from "../../store/store";
import "./progressbar.css";

export default function Progressbar() {
    const stepsProductionObj: any = useAppSelector((state) => state.stepProduction.stepsProductionObj[0]);
    console.log("In progressbar", stepsProductionObj);


    const arr = [];
    for (let i = 0; i < stepsProductionObj.length; i++) {
        console.log("inside for loop", stepsProductionObj[i])
        if (stepsProductionObj[i].linkStepStatusId === 4) {
            arr.push({"id": i, "step": "completed"});
        } else {
            arr.push({"id": i, "step": "active"});
        }
    }


    return (
        <>

            <Container className="bar-container">
                <div className="stepper-wrapper">
                    {arr.map((item: any) => (
                        <div key={item.id} className={`stepper-item ${item.step}`}>
                            <div className="step-counter">{item.id + 1}</div>
                        </div>
                    ))}
                </div>
            </Container>
        </>
    );
}