// *******REDIRECT AUTH URL********************
const devRedirectAuthUrl = "https://neuronah2o.com"
const prodRedirectAuthUrl = "https://simplifiedsystemprocess.com"

export const baseAuthUrl = prodRedirectAuthUrl

// *********BACKEND API URL****************
const ngrokBackEndApi = "https://76e9-104-6-74-98.ngrok-free.app"
const devBackEndApi = "https://formsapi-image-lhvsi7s7na-ue.a.run.app"
const prodBackEndApi = "https://dpsformsapi-e2idqgx6eq-ue.a.run.app"


export const billingApiUrl = prodBackEndApi