import {Button, Container, Spinner} from "react-bootstrap";
import AppLayout from "../Layout/AppLayout";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../store/store";
import {useContext, useEffect, useRef, useState} from "react";
import {billingApiUrl} from "../Components/APIs/urls";
//import { UserLinkIdStatus } from "../App";
import {updateStepComplete, updateStepInProcess} from "../store/features/stepSliceProduction";
import {continueFalse} from "../store/features/continueSteps";
import {UserContext} from "../Types/UserContext";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../FirebaseConfig";
import {useAuthState} from "react-firebase-hooks/auth";
import Error401 from "./Error401";
import Cookies from "universal-cookie";
import {useTranslation} from "react-i18next";
import {resetAuthObj} from "../store/features/authSlice";


export default function InvoiceDetails() {
    //const { userLinkId }: any = useContext(UserLinkIdStatus);
    const {userLinkId} = useContext(UserContext)

    const cookies = new Cookies();

    const {t, i18n} = useTranslation();
    const lng = navigator.language;

    const [user] = useAuthState(auth);


    const API_KEY = process.env.REACT_APP_API_KEY + "";

    const [data, setData] = useState([]);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("")
    const [authToken, setAuthToken] = useState("");

    const stepIndex: any = useRef()
    const isClickedInvoice = useRef(false);
    const TokenValid = useRef(true);

    let stepsProductionObj: any = useAppSelector((state) => state.stepProduction?.stepsProductionObj[0]);
    let isContinue = useAppSelector((state) => state.continue.isContinue)
    const authObj: any = useAppSelector((state) => state.auth.authObj);
    const linkObj: any = useAppSelector((state) => state.link.linkObj);
    console.log("isContinue: ", isContinue)


    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        auth.signOut().then(() => {
            console.log("Token expired. Get new one")
            cookies.remove("jwt_authorization");
            dispatch(resetAuthObj());
            navigate("/auth")
        }).catch((err) => {
            console.log(err);
        })
    }

    const getAuthToken = async () => {

        //GET CURRENT TIME
        const currentTime: any = new Date();
        console.log("Current Time", currentTime);

        console.log("Auth OBJ", authObj)
        const dateFormatted = new Date(Date.parse(authObj.exp_Date))
        console.log("Auth OBJ date formatted", dateFormatted)

        //COMPARE IF TOKEN IS STILL VALID
        if (dateFormatted > currentTime) {
            console.log("current time inside Patient Invoice", currentTime);
            console.log("Token not yet expired Inside Patient Invoice")
        } else {
            TokenValid.current = false;
            console.log("Token expired inside patient invoice!!", TokenValid.current)
            handleLogout();
        }

    }

    const handleInvoiceEvent = (e: any) => {
        if (e.origin === "https://systemprocess.jotform.com") {
            console.log("signed document", e.signedDocumentURL)
            console.log("origin matched")
            console.log("data", e.data)
            console.log("data action", e.data.action);
            if (e.data.action === "submission-completed") {
                console.log("event message after signature", e);
                console.log("form signed", e.data.action);
                setIsDisabled(false);
            }
        }
    };


    for (let i = 0; i < stepsProductionObj.length; i++) {
        if (stepsProductionObj[i].routePath === '/patientinvoicedetail') {
            console.log("Inside LOOP Match", stepsProductionObj[i].routePath);
            stepIndex.current = i;
        }
    }

    useEffect(() => {
        getAuthToken();
        // window.addEventListener("popstate", e => {
        //   navigate(1);
        // })
        window.history.pushState(null, '', window.location.href);
        window.addEventListener("popstate", e => {
            window.history.pushState(null, '', window.location.href);
            console.log("EVENT IN BACK Patient Invoice", e)
            //navigate(1);
            return () => {
                window.removeEventListener('popstate', (e: PopStateEvent) => {

                })
            }
        })

        onAuthStateChanged(auth, (data) => {
            console.log("user data", data);
            console.log("user", auth.currentUser);
            if (!data) {
                navigate("/auth");
            } else {
                console.log("continue", isContinue)
                const stepParams = new URLSearchParams();
                stepParams.append("linkStepId", stepsProductionObj[stepIndex.current].linkStepId);
                stepParams.append("LinkStepStatusId", "2");
                stepParams.append("linkId", cookies.get('link_id'));
                //**** CALL TO BACKEND API - UPDATE PATIENT INVOICE STEP IN BACKEND TO - IN PROGRESS
                const fetchDataUpdate = async () => {
                    try {
                        const resp = await fetch(`${billingApiUrl}/api/linksteps?` + stepParams.toString(), {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                                "ngrok-skip-browser-warning": "69420",
                                "x-api-key": API_KEY,
                                "Authorization": "Bearer" + " " + cookies.get('jwt_authorization')
                            },
                        });
                        if (resp.ok) {
                            console.log("response okay invoice update")
                            dispatch(updateStepInProcess({
                                routePath: stepsProductionObj[stepIndex.current].routePath,
                                linkStepStatusId: 2
                            }));
                        } else if (!resp.ok) {
                            setLoading(false);
                            //document.body.style.backgroundColor = "#551A8B";
                            if (resp.statusText) {
                                setError(resp.status + " " + resp.statusText)
                            } else {
                                const result = await resp.text()
                                setError(resp.status + " " + result)
                            }
                        }
                    } catch (error: any) {
                        console.error(`Error fetching data: ${error.message}`);
                        setLoading(false);
                        navigate("*")
                    }
                };
                //FUNCTION CALL
                if (!isContinue) {
                    if (stepsProductionObj[stepIndex.current].linkStepStatusId !== 2 && stepsProductionObj[stepIndex.current].linkStepStatusId !== 4) {
                        console.log("LinkStepStatus in Invoice", stepsProductionObj[stepIndex.current].linkStepStatusId);
                        TokenValid.current && fetchDataUpdate();
                    }
                } else {
                    dispatch(continueFalse());
                    console.log("This is a continue don't update this step but update the next")
                }

                if (!window.addEventListener) {
                    console.log("hello");
                } else {
                    window.addEventListener("message", handleInvoiceEvent, false);
                }
            }
        })
    }, []);

    const handleFinish = () => {
        isClickedInvoice.current = true;
        console.log("IS Clicked in Invoice", isClickedInvoice.current)
        setLoading(true);
        getAuthToken();
        const fetchData = async () => {
            try {
                const stepParams = new URLSearchParams();
                stepParams.append("linkId", cookies.get('link_id'));
                stepParams.append("linkStepId", stepsProductionObj[stepIndex.current].linkStepId);
                stepParams.append("LinkStepStatusId", "4");
                //**** CALL TO BACKEND API - UPDATE PATIENT ID STEP IN BACKEND TO - COMPLETE
                const resp = await fetch(`${billingApiUrl}/api/linksteps?` + stepParams.toString(), {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "ngrok-skip-browser-warning": "69420",
                        "x-api-key": API_KEY,
                        "Authorization": "Bearer" + " " + cookies.get('jwt_authorization')
                    },
                });
                if (resp.ok) {
                    console.log("response okay invoice complete")
                    dispatch(updateStepComplete({
                        routePath: stepsProductionObj[stepIndex.current].routePath,
                        linkStepStatusId: 4
                    }));
                    setLoading(false);
                    navigate(stepsProductionObj[stepIndex.current + 1].routePath);
                } else if (!resp.ok) {
                    setLoading(false);
                    //document.body.style.backgroundColor = "#551A8B";
                    if (resp.statusText) {
                        setError(resp.status + " " + resp.statusText)
                    } else {
                        const result = await resp.text()
                        setError(resp.status + " " + result)
                    }
                }
            } catch (error: any) {
                console.error(`Error fetching data: ${error.message}`);
                setLoading(false);
                navigate("*")
            }
        };
        TokenValid.current && fetchData();
    };

    return (
        <>
            {!stepsProductionObj ?
                <>
                    <Error401/>
                </>
                :
                <>
                    {loading && (
                        <div className="spinner-cont">
                            <Spinner className="spinner" animation="border" role="status"
                                     style={{width: "4rem", height: "4rem"}}>
                                <span className="visually-hidden">Loading</span>
                            </Spinner>
                        </div>
                    )}
                    {!error ?
                        <>
                            <AppLayout/>
                            <h1>{t('patientInvoiceTitle')}</h1>
                            <Container>
                                <iframe
                                    className="frame-invoice"
                                    src={`${stepsProductionObj[stepIndex.current].documentUrl}#view=fitH`}
                                    title="testPdf"
                                    height="575"
                                    width="100%"
                                ></iframe>
                            </Container>
                            <div className="btn-location">
                                <Button className="btn-invoice" disabled={isDisabled || isClickedInvoice.current}
                                        onClick={handleFinish}>
                                    {t('patientInvoiceBtn')}
                                </Button>
                            </div>
                        </>
                        :
                        <Container className="error-display-container">
                            <h3>Error {error}</h3>
                        </Container>}
                </>}
        </>
    );
}
