import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface Auth {
    authEmail: string;
    authToken: string;
    expTime: Date;
}

interface AuthState {
    authObj: Auth;
}

const initialState: AuthState = {
    authObj: {
        authEmail: "",
        authToken: "",
        expTime: new Date()
    },
};

export const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        updateToken: (state, action: PayloadAction<{ access_token: string }>) => {
            const {access_token} = action.payload;
            const updatedAuthToken: any = state.authObj as Auth;
            updatedAuthToken.access_token = access_token;
        },
        updateEmail: (state, action: PayloadAction<{ auth_email: string }>) => {
            const {auth_email} = action.payload;
            const updatedAuthEmail: any = state.authObj as Auth;
            updatedAuthEmail.auth_email = auth_email;
        },
        updateDate: (state, action: PayloadAction<{ exp_Date: Date }>) => {
            const {exp_Date} = action.payload;
            const updatedExpDate: any = state.authObj as Auth;
            updatedExpDate.exp_Date = exp_Date;
            console.log("Expiration Date inside REDUX", updatedExpDate.exp_Date);
        },
        resetAuthObj: (state) => {
            return {
                authObj: {
                    authEmail: "",
                    authToken: "",
                    expTime: new Date()
                }
            }
        }
    },
});

export default AuthSlice.reducer;
export const {updateToken, updateEmail, updateDate, resetAuthObj} = AuthSlice.actions;