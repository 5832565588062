import {Button, Container, Spinner} from "react-bootstrap";
import AppLayout from "../Layout/AppLayout";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../store/store";
import {useContext, useEffect, useRef, useState} from "react";
import IdInstructions from "../Components/IdComponent/idInstructions";
import {billingApiUrl} from "../Components/APIs/urls";
//import { UserLinkIdStatus } from "../App";
import {updateStepComplete} from "../store/features/stepSliceProduction";
import {UserContext} from "../Types/UserContext";
import {auth} from '../FirebaseConfig'
import {useAuthState} from "react-firebase-hooks/auth";
import {onAuthStateChanged} from "firebase/auth";
import Error401 from "./Error401";
import Cookies from "universal-cookie";
import {useTranslation} from "react-i18next";


export default function PatientId() {
    //const { userLinkId }: any = useContext(UserLinkIdStatus);
    const {userLinkId} = useContext(UserContext)


    const API_KEY = process.env.REACT_APP_API_KEY + "";

    const cookies = new Cookies();

    const {t, i18n} = useTranslation();
    const lng = navigator.language;

    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [error, setError] = useState("");

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [user] = useAuthState(auth);
    const [userData, setUserData] = useState();

    const stepIndex: any = useRef()
    const isClickedPatientId = useRef(false);
    const TokenValid = useRef(true);

    const stepsProductionObj: any = useAppSelector((state) => state.stepProduction.stepsProductionObj[0])
    const linkObj: any = useAppSelector((state) => state.link.linkObj);
    const authObj: any = useAppSelector((state) => state.auth.authObj);

    const handleLogout = async () => {
        auth.signOut().then(() => {
            console.log("Token expired. Get new one")
            cookies.remove("jwt_authorization");
            navigate("/auth")
        }).catch((err) => {
            console.log(err);
        })
    }

    const getAuthToken = async () => {

        //GET CURRENT TIME
        const currentTime: any = new Date();
        console.log("Current Time", currentTime);

        console.log("Auth OBJ", authObj)
        const dateFormatted = new Date(Date.parse(authObj.exp_Date))
        console.log("Auth OBJ date formatted", dateFormatted)

        //COMPARE IF TOKEN IS STILL VALID
        if (dateFormatted > currentTime) {
            console.log("current time inside PatientID", currentTime);
            console.log("Token not yet expired Inside Patient Id")
        } else {
            TokenValid.current = false;
            console.log("Token expired inside patient id!!")
            handleLogout();
        }

    }

    //data.action === 'submission-completed'
    function eventHandler(e: any) {
        console.log("event message", e);
        if (e.origin === "https://systemprocess.jotform.com") {
            console.log("signed document", e.signedDocumentURL)
            console.log("origin matched")
            console.log("data", e.data)
            console.log("data action", e.data.action);
            if (e.data.action === "submission-completed") {
                console.log("event message after signature", e);
                console.log("form signed", e.data.action);
                setIsDisabled(false);
            }
        }
    }

    if (stepsProductionObj) {
        for (let i = 0; i < stepsProductionObj.length; i++) {
            if (stepsProductionObj[i].routePath === '/patientiddocs') {
                console.log("Inside LOOP Match", stepsProductionObj[i].routePath);
                stepIndex.current = i;
            }
        }
    }

    const location = useLocation();
    useEffect(() => {
        getAuthToken();
        console.log("After get authToken in patient id");
        window.history.pushState(null, '', window.location.href);
        window.addEventListener("popstate", e => {
            window.history.pushState(null, '', window.location.href);
            console.log("Event Back Patient Id")
            return () => {
                window.removeEventListener('popstate', (e: PopStateEvent) => {

                })
            }
            // navigate(1);
            // console.log("Log after navigate", stepsProductionObj);
            // if(stepsProductionObj) {
            //   for(let i = 0; i < stepsProductionObj.length; i++) {
            //     if(stepsProductionObj[i].routePath === '/patientiddocs') {
            //       console.log("Inside LOOP Match inside popstate", stepsProductionObj[i].routePath);
            //       stepIndex.current = i;
            //     }
            //   }
            // }
        })
        onAuthStateChanged(auth, (data) => {
            console.log("user data", data);
            console.log("user", auth.currentUser);
            if (!data) {
                console.log("inside no data block to re-route to 401", data)
            } else {
                if (!window.addEventListener) {
                    console.log("hello");
                } else {
                    window.addEventListener("message", eventHandler, false);
                }
            }
        })

    }, []);

    const handleNext = async () => {
        getAuthToken();
        isClickedPatientId.current = true;
        console.log("IS Clicked in Patient ID", isClickedPatientId.current)
        setLoading(true);
        const fetchData = async () => {
            try {
                console.log("Calling getAuthToken in Patient ID when hit next button")
                console.log("user link id", userLinkId.id)
                console.log("user link id 2", linkObj.link_id);
                const stepParams = new URLSearchParams();
                stepParams.append("linkId", cookies.get('link_id'));
                stepParams.append("linkStepId", stepsProductionObj[stepIndex.current].linkStepId);
                stepParams.append("LinkStepStatusId", "4");
                //**** CALL TO BACKEND API - UPDATE PATIENT ID STEP IN BACKEND TO - COMPLETE
                const resp = await fetch(`${billingApiUrl}/api/linksteps?` + stepParams.toString(), {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "ngrok-skip-browser-warning": "69420",
                        "x-api-key": API_KEY,
                        "Authorization": "Bearer" + " " + cookies.get('jwt_authorization')
                    },
                });
                if (resp.ok) {
                    console.log("response okay patientId complete")
                    dispatch(updateStepComplete({
                        routePath: stepsProductionObj[stepIndex.current].routePath,
                        linkStepStatusId: 4
                    }));
                    setLoading(false);
                    navigate(stepsProductionObj[stepIndex.current + 1].routePath);
                } else if (!resp.ok) {
                    setLoading(false);
                    //document.body.style.backgroundColor = "#551A8B";
                    if (resp.statusText) {
                        setError(resp.status + " " + resp.statusText)
                    } else {
                        const result = await resp.text()
                        setError(resp.status + " " + result)
                    }
                }

            } catch (error: any) {
                console.error(`Error fetching data: ${error.message}`);
                setLoading(false);
                navigate("*");
            }
        };
        TokenValid.current && fetchData();

    };


    return (
        <>
            {!stepsProductionObj ?
                <>
                    <Error401/>
                </>
                :
                <>
                    {loading && (
                        <div className="spinner-cont">
                            <Spinner className="spinner" animation="border" role="status"
                                     style={{width: "4rem", height: "4rem"}}>
                                <span className="visually-hidden">Loading</span>
                            </Spinner>
                        </div>
                    )}
                    {!error ?
                        <>
                            <AppLayout/>
                            <div className="id-title">
                                <h1>{t('patientIdTitle')}</h1>
                            </div>
                            <div className="id-text-instructions">
                                <p>{t('patientIdInstructionTitle')}</p>
                            </div>
                            <IdInstructions/>
                            <Container>
                                <iframe src={stepsProductionObj[stepIndex.current].documentUrl} title="id" width="100%"
                                        height="575"></iframe>
                                {/* <iframe id="iframeObj" title="Sample Agreement" src="https://systemprocess.jotform.com/233185017921049/prefill/654d1e5037623243a4af95944241
        " width="100%" height="700"></iframe> */}
                            </Container>
                            <div className="btn-location">
                                <Button className="btn-idPage" disabled={isDisabled || isClickedPatientId.current}
                                        onClick={handleNext}>
                                    {t('patientIdBtn')}
                                </Button>
                            </div>
                        </>
                        :
                        <Container className="error-display-container">
                            <h3>Error {error}</h3>
                        </Container>}
                </>}
        </>
    );
}
