import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookies = new Cookies;

export interface Link {
    redirectLink: string;
    linkId: string;
    linkIdType: string;
}

interface LinkState {
    linkObj: Link;
}

const initialState: LinkState = {
    linkObj: {
        redirectLink: "",
        linkId: "",
        linkIdType: ""
    },
};

export const LinkSlice = createSlice({
    name: "link",
    initialState,
    reducers: {
        updateLink: (state, action: PayloadAction<{ redirect_link: string }>) => {
            const {redirect_link} = action.payload;
            const updatedLink: any = state.linkObj as Link;
            updatedLink.redirect_link = redirect_link;
            console.log("LINK FROM INSIDE REDUX ENTIRE LINK", updatedLink.redirect_link);
        },
        updateLinkId: (state, action: PayloadAction<{ link_id: string }>) => {
            const {link_id} = action.payload;
            const updatedLinkId: any = state.linkObj as Link;
            updatedLinkId.link_id = link_id;
            console.log("INSIDE REDUX ID", updatedLinkId.link_id)
            if (!cookies.get('link_id')) {
                cookies.set('link_id', updatedLinkId.link_id);
                console.log("link id set inside redux call", updatedLinkId.link_id)
            }

        },
        updateLinkIdType: (state, action: PayloadAction<{ link_id_type: string }>) => {
            const {link_id_type} = action.payload;
            const updatedLinkIdType: any = state.linkObj as Link;
            updatedLinkIdType.link_id_type = link_id_type;
            console.log("INSIDE REDUX LINK ID TYPE", updatedLinkIdType.link_id_type)
            if (!cookies.get('link_id_type')) {
                cookies.set('link_id_type', updatedLinkIdType.link_id_type);
                console.log("link id set inside redux call", updatedLinkIdType.link_id_type)
            }

        },
        resetLink: (state) => {
            return {
                linkObj: {
                    redirectLink: "",
                    linkId: "",
                    linkIdType: ""
                }
            }
        }
    },
});

export default LinkSlice.reducer;
export const {updateLink, updateLinkId, updateLinkIdType, resetLink} = LinkSlice.actions;
